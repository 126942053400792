import React, { useState, useEffect } from 'react';
import { useNavigate, Link as RouterLink, useLocation } from 'react-router-dom';
import API from '../api/api';
import { useAuth } from '../context/AuthContext';
import LapasPoLapoLogo from '../assets/lapas_login.png';
import {
  Button,
  TextField,
  Container,
  Box,
  Alert,
  Typography,
  Divider,
  Link
} from '@mui/material';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const [verificationMessage, setVerificationMessage] = useState('');
  const [verificationError, setVerificationError] = useState('');
  const [passwordChangedMessage, setPasswordChangedMessage] = useState('');

  const navigate = useNavigate();
  const { login } = useAuth();
  const location = useLocation();

  useEffect(() => {
    // Clear any existing tokens on component mount
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');

    // Parse query parameters
    const params = new URLSearchParams(location.search);
    const verified = params.get('verified');
    const errorParam = params.get('error');
    const passwordChanged = params.get('password_changed');

    if (verified === 'true') {
      setVerificationMessage('Jūsų el. paštas sėkmingai patvirtintas. Prašome prisijungti.');
    } else if (verified === 'false' && errorParam === 'already_verified') {
      setVerificationError('Jūsų el. paštas jau buvo patvirtintas. Prašome prisijungti.');
    }

    if (passwordChanged === 'true') {
      setPasswordChangedMessage('Jūsų slaptažodis buvo sėkmingai pakeistas. Prašome prisijungti.');
    }
  }, [location.search]);

  const handleLogin = async () => {
    try {
      const response = await API.post('/users/login/', { username, password });
      const { access, refresh } = response.data;
      login(access, refresh);
      navigate('/all-challenges');
    } catch (error) {
      console.error('Error during login:', error);
      setError('Neteisingas vartotojo vardas arba slaptažodis.');
    }
  };

  return (
    <Container maxWidth="xs">
      <Box mt={8} display="flex" flexDirection="column" alignItems="center">
        {/* Logo */}
        <img src={LapasPoLapoLogo} alt="Lapas po lapo Logo" style={{ width: 120, height: 120, marginBottom: '20px' }} />

        {/* Alerts */}
        {verificationMessage && (
          <Alert severity="success" sx={{ width: '100%', mb: 2 }}>
            {verificationMessage}
          </Alert>
        )}
        {verificationError && (
          <Alert severity="warning" sx={{ width: '100%', mb: 2 }}>
            {verificationError}
          </Alert>
        )}
        {passwordChangedMessage && (
          <Alert severity="info" sx={{ width: '100%', mb: 2 }}>
            {passwordChangedMessage}
          </Alert>
        )}
        {error && (
          <Alert severity="error" sx={{ width: '100%', mb: 2 }}>
            {error}
          </Alert>
        )}

        {/* Login Form */}
        <Box component="form" noValidate sx={{ mt: 1 }} onSubmit={(e) => { e.preventDefault(); handleLogin(); }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="username"
            label="Vartotojo vardas"
            name="username"
            autoComplete="username"
            autoFocus
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Slaptažodis"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button type="submit" fullWidth variant="contained" color="primary" onClick={handleLogin} sx={{ mt: 3, mb: 2 }}>
            Prisijungti
          </Button>

          {/* Register Link */}
          <Typography variant="body2" align="center">
            Neturite paskyros?{' '}
            <Link component={RouterLink} to="/register" variant="body2">
              Registruotis
            </Link>
          </Typography>
        </Box>
      </Box>

      {/* Footer */}
      <Box component="footer" sx={{ marginTop: 'auto', textAlign: 'center', py: 3 }}>
        <Divider />
        <Typography variant="subtitle1" sx={{ mt: 2 }}>
          Skaitome kartu, augame kartu
        </Typography>
      </Box>
    </Container>
  );
};

export default Login;
