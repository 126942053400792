// src/components/MyChallenges.js
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import API, { baseURL } from '../api/api';
import {
  Container,
  Typography,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  Avatar,
  Grid,
  Box,
} from '@mui/material';
import { useAuth } from '../context/AuthContext';

const MyChallenges = () => {
  const { currentUser } = useAuth();
  const [challenges, setChallenges] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchMyChallenges = async () => {
      try {
        if (!currentUser) {
          setIsLoading(false);
          return;
        }

        const response = await API.get('/club/challenges/users/');
        let userChallenges = response.data;

        if (Array.isArray(response.data.results)) {
          userChallenges = response.data.results;
        }

        if (!Array.isArray(userChallenges)) {
          console.error('Unexpected API response format:', response.data);
          setIsLoading(false);
          return;
        }

        const challengesData = userChallenges.map(
          (userChallenge) => userChallenge.challenge
        );

        setChallenges(challengesData);
      } catch (error) {
        console.error('Failed to fetch user challenges:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchMyChallenges();
  }, [currentUser]);

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" mt={4}>
        <CircularProgress />
      </Box>
    );
  }

  if (!currentUser) {
    return (
      <Container maxWidth="md" sx={{ marginTop: '2rem' }}>
        <Typography variant="h4" gutterBottom>
          Mano skaitymo iššūkiai
        </Typography>
        <Typography variant="body1">
          Norėdami matyti savo skaitymo iššūkius, turite prisijungti
        </Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="md" sx={{ marginTop: '2rem' }}>
      <Typography variant="h4" gutterBottom>
        Mano skaitymo iššūkiai
      </Typography>
      {challenges.length > 0 ? (
        <List>
          {challenges.map((challenge) => (
            <ListItem
              key={challenge.id}
              component={Link}
              to={`/club/challenges/${challenge.id}`}
              sx={{
                backgroundColor: 'secondary.main',
                marginBottom: '1rem',
                padding: '1rem',
                borderRadius: '8px',
                color: 'text.primary',
                textDecoration: 'none',
                '&:visited': {
                  color: 'text.primary',
                },
              }}
            >
              <Grid container alignItems="center" spacing={2}>
                <Grid item xs={12} md={4}>
                  <Box display="flex" flexDirection="row">
                    {challenge.books.map((book) => {
                      const coverURL = book.cover
                        ? book.cover.startsWith('http')
                          ? book.cover
                          : `${baseURL}${book.cover}`
                        : '/default_cover.jpg';

                      return (
                        <Avatar
                          key={book.id}
                          src={coverURL}
                          alt={book.title}
                          sx={{ width: 100, height: 150, marginRight: '8px' }}
                          variant="rounded"
                        />
                      );
                    })}
                  </Box>
                </Grid>
                <Grid item xs={12} md={8}>
                  <ListItemText
                    primary={
                      <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'text.primary' }}>
                        {challenge.name}
                      </Typography>
                    }
                  />
                </Grid>
              </Grid>
            </ListItem>
          ))}
        </List>
      ) : (
        <Typography variant="body1" sx={{ color: 'text.primary' }}>
          Kol kas nepasiryžote jokiam skaitymo iššūkiui
        </Typography>
      )}
    </Container>
  );
};

export default MyChallenges;